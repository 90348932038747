import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';

export class TechRadarClient implements TechRadarApi {
  async load(): Promise<TechRadarLoaderResponse> {
    const response = await fetch(
      'https://api.jsonbin.io/v3/b/6645cfc9ad19ca34f86a49a5',
    ).then(res => res.json());

    return {
      ...response.record.data,
    };
  }
}
