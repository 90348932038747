import React from 'react';

import MyCustomLogoFull from './cars24.png';

const LogoFull = () => {
  return <img width= '140px' src={MyCustomLogoFull} />;
};


export default LogoFull;
